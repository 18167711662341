export var ContactType;
(function (ContactType) {
    ContactType["CUSTOM"] = "CUSTOM";
    ContactType["CLOUD"] = "CLOUD";
})(ContactType || (ContactType = {}));
export var GroupType;
(function (GroupType) {
    GroupType["NORMAL"] = "NORMAL";
    GroupType["EXTERNAL"] = "EXTERNAL";
})(GroupType || (GroupType = {}));
