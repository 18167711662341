export var METRIC_TYPE;
(function (METRIC_TYPE) {
    METRIC_TYPE["OPERATIONAL"] = "operational";
    METRIC_TYPE["BEHAVIORAL"] = "behavioral";
})(METRIC_TYPE || (METRIC_TYPE = {}));
export var METRIC_EVENT;
(function (METRIC_EVENT) {
    METRIC_EVENT["BNR_ENABLED"] = "web-calling-sdk-bnr-enabled";
    METRIC_EVENT["BNR_DISABLED"] = "web-calling-sdk-bnr-disabled";
    METRIC_EVENT["CALL"] = "web-calling-sdk-callcontrol";
    METRIC_EVENT["CALL_ERROR"] = "web-calling-sdk-callcontrol-error";
    METRIC_EVENT["MEDIA"] = "web-calling-sdk-media";
    METRIC_EVENT["MEDIA_ERROR"] = "web-calling-sdk-media-error";
    METRIC_EVENT["REGISTRATION"] = "web-calling-sdk-registration";
    METRIC_EVENT["REGISTRATION_ERROR"] = "web-calling-sdk-registration-error";
    METRIC_EVENT["VOICEMAIL"] = "web-calling-sdk-voicemail";
    METRIC_EVENT["VOICEMAIL_ERROR"] = "web-calling-sdk-voicemail-error";
})(METRIC_EVENT || (METRIC_EVENT = {}));
export var REG_ACTION;
(function (REG_ACTION) {
    REG_ACTION["REGISTER"] = "register";
    REG_ACTION["DEREGISTER"] = "deregister";
    REG_ACTION["KEEPALIVE_FAILURE"] = "keepaliveFailure";
})(REG_ACTION || (REG_ACTION = {}));
export var TRANSFER_ACTION;
(function (TRANSFER_ACTION) {
    TRANSFER_ACTION["BLIND"] = "TRANSFER_BLIND";
    TRANSFER_ACTION["CONSULT"] = "TRANSFER_CONSULT";
})(TRANSFER_ACTION || (TRANSFER_ACTION = {}));
export var VOICEMAIL_ACTION;
(function (VOICEMAIL_ACTION) {
    VOICEMAIL_ACTION["GET_VOICEMAILS"] = "get_voicemails";
    VOICEMAIL_ACTION["GET_VOICEMAIL_CONTENT"] = "get_voicemail_content";
    VOICEMAIL_ACTION["GET_VOICEMAIL_SUMMARY"] = "get_voicemail_summary";
    VOICEMAIL_ACTION["MARK_READ"] = "mark_read";
    VOICEMAIL_ACTION["MARK_UNREAD"] = "mark_unread";
    VOICEMAIL_ACTION["DELETE"] = "delete";
    VOICEMAIL_ACTION["TRANSCRIPT"] = "transcript";
})(VOICEMAIL_ACTION || (VOICEMAIL_ACTION = {}));
