import ExtendedError from './ExtendedError';
export class CallError extends ExtendedError {
    correlationId;
    errorLayer;
    constructor(msg, context, type, correlationId, errorLayer) {
        super(msg, context, type);
        this.correlationId = correlationId;
        this.errorLayer = errorLayer;
    }
    setCallError(error) {
        this.message = error.message;
        this.correlationId = error.correlationId;
        this.context = error.context;
        this.type = error.type;
    }
    getCallError() {
        return {
            message: this.message,
            context: this.context,
            type: this.type,
            correlationId: this.correlationId,
            errorLayer: this.errorLayer,
        };
    }
}
export const createCallError = (msg, context, type, correlationId, errorLayer) => new CallError(msg, context, type, correlationId, errorLayer);
