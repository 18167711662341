import log from '../Logger';
import SDKConnector from '../SDKConnector';
import { serviceErrorCodeHandler } from '../common/Utils';
import { FAILURE_MESSAGE, STATUS_CODE, SUCCESS_MESSAGE, UCM_CONNECTOR_FILE, VOICEMAIL, } from '../common/constants';
import { HTTP_METHODS } from '../common/types';
import { CF_ENDPOINT, ORG_ENDPOINT, PEOPLE_ENDPOINT, WEBEX_APIS_INT_URL, WEBEX_APIS_PROD_URL, } from './constants';
export class UcmBackendConnector {
    sdkConnector;
    webex;
    userId;
    orgId;
    useProdWebexApis;
    constructor(webex, logger, useProdWebexApis = true) {
        this.sdkConnector = SDKConnector;
        if (!this.sdkConnector.getWebex()) {
            SDKConnector.setWebex(webex);
        }
        this.webex = this.sdkConnector.getWebex();
        log.setLogger(logger.level, UCM_CONNECTOR_FILE);
        this.userId = this.webex.internal.device.userId;
        this.orgId = this.webex.internal.device.orgId;
        this.useProdWebexApis = useProdWebexApis;
    }
    getCallWaitingSetting() {
        return this.getMethodNotSupportedResponse();
    }
    getDoNotDisturbSetting() {
        return this.getMethodNotSupportedResponse();
    }
    setDoNotDisturbSetting() {
        return this.getMethodNotSupportedResponse();
    }
    getCallForwardSetting() {
        return this.getMethodNotSupportedResponse();
    }
    setCallForwardSetting() {
        return this.getMethodNotSupportedResponse();
    }
    getVoicemailSetting() {
        return this.getMethodNotSupportedResponse();
    }
    setVoicemailSetting() {
        return this.getMethodNotSupportedResponse();
    }
    getMethodNotSupportedResponse() {
        const response = serviceErrorCodeHandler({ statusCode: 501 }, { file: UCM_CONNECTOR_FILE, method: this.getMethodNotSupportedResponse.name });
        return Promise.resolve(response);
    }
    async getCallForwardAlwaysSetting(directoryNumber) {
        const loggerContext = {
            file: UCM_CONNECTOR_FILE,
            method: this.getCallForwardAlwaysSetting.name,
        };
        const webexApisUrl = this.useProdWebexApis ? WEBEX_APIS_PROD_URL : WEBEX_APIS_INT_URL;
        try {
            if (directoryNumber) {
                const resp = await this.webex.request({
                    uri: `${webexApisUrl}/${PEOPLE_ENDPOINT}/${this.userId}/${CF_ENDPOINT.toLowerCase()}?${ORG_ENDPOINT}=${this.orgId}`,
                    method: HTTP_METHODS.GET,
                });
                const { callForwarding } = resp.body;
                const cfa = callForwarding.always.find((item) => item.dn.endsWith(directoryNumber) || item.e164Number.endsWith(directoryNumber));
                if (cfa) {
                    const response = {
                        statusCode: Number(resp[STATUS_CODE]),
                        message: SUCCESS_MESSAGE,
                        data: {
                            callSetting: {
                                enabled: cfa.destinationVoicemailEnabled || !!cfa.destination,
                                destination: cfa.destinationVoicemailEnabled ? VOICEMAIL : cfa.destination,
                            },
                        },
                    };
                    return response;
                }
                const response = {
                    statusCode: 404,
                    message: FAILURE_MESSAGE,
                    data: {
                        error: 'Directory Number is not assigned to the user',
                    },
                };
                return response;
            }
            const response = {
                statusCode: 400,
                message: FAILURE_MESSAGE,
                data: {
                    error: 'Directory Number is mandatory for UCM backend',
                },
            };
            return response;
        }
        catch (err) {
            const errorInfo = err;
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            return errorStatus;
        }
    }
}
