import { CALL_MANAGER_FILE } from '../constants';
import { CALLING_CLIENT_EVENT_KEYS, LINE_EVENT_KEYS } from '../../Events/types';
import { Eventing } from '../../Events/impl';
import SDKConnector from '../../SDKConnector';
import { CallDirection } from '../../common/types';
import { MediaState, MobiusEventType, } from './types';
import { createCall } from './call';
import log from '../../Logger';
let callManager;
export class CallManager extends Eventing {
    sdkConnector;
    webex;
    callCollection;
    activeMobiusUrl;
    serviceIndicator;
    lineDict;
    constructor(webex, indicator) {
        super();
        this.sdkConnector = SDKConnector;
        this.serviceIndicator = indicator;
        if (!this.sdkConnector.getWebex()) {
            SDKConnector.setWebex(webex);
        }
        this.lineDict = {};
        this.webex = this.sdkConnector.getWebex();
        this.callCollection = {};
        this.activeMobiusUrl = '';
        this.listenForWsEvents();
    }
    createCall = (destination, direction, deviceId, lineId) => {
        log.log('Creating call object', {});
        const newCall = createCall(this.activeMobiusUrl, this.webex, destination, direction, deviceId, lineId, (correlationId) => {
            delete this.callCollection[correlationId];
            const activeCalls = Object.keys(this.getActiveCalls()).length;
            log.info(`DELETE:: Deleted corelationId: ${newCall.getCorrelationId()} from CallManager, Number of call records :- ${activeCalls}`, {});
            if (activeCalls === 0) {
                this.emit(CALLING_CLIENT_EVENT_KEYS.ALL_CALLS_CLEARED);
            }
        }, this.serviceIndicator);
        this.callCollection[newCall.getCorrelationId()] = newCall;
        log.log(`New call created with correlationId: ${newCall.getCorrelationId()}`, {});
        log.info(`ADD:: Added corelationId: ${newCall.getCorrelationId()} to CallManager , Number of call records now:- ${Object.keys(this.getActiveCalls()).length}`, {});
        return newCall;
    };
    updateActiveMobius(url) {
        this.activeMobiusUrl = url;
    }
    listenForWsEvents() {
        this.sdkConnector.registerListener('event:mobius', async (event) => {
            this.dequeueWsEvents(event);
        });
    }
    dequeueWsEvents(event) {
        const mobiusEvent = event;
        const { callId, correlationId } = mobiusEvent.data;
        switch (mobiusEvent.data.eventType) {
            case MobiusEventType.CALL_SETUP: {
                log.log(`Received call Setup message for call: ${callId}`, {
                    file: CALL_MANAGER_FILE,
                    method: 'dequeueWsEvents',
                });
                if (mobiusEvent.data.midCallService) {
                    mobiusEvent.data.midCallService.forEach((midCallEvent) => {
                        const call = this.getCall(correlationId);
                        if (call) {
                            call.handleMidCallEvent(midCallEvent);
                        }
                        else {
                            log.log(`Dropping midcall event of type: ${midCallEvent.eventType} as it doesn't match with any existing call`, {
                                file: CALL_MANAGER_FILE,
                                method: 'dequeueWsEvents',
                            });
                        }
                    });
                    return;
                }
                const newId = Object.keys(this.callCollection).find((id) => this.callCollection[id].getCallId() === callId);
                let newCall;
                if (!newId) {
                    const lineId = this.getLineId(mobiusEvent.data.deviceId);
                    newCall = this.createCall({}, CallDirection.INBOUND, mobiusEvent.data.deviceId, lineId);
                    log.log(`New incoming call created with correlationId from Call Setup message: ${newCall.getCorrelationId()}`, {
                        file: CALL_MANAGER_FILE,
                        method: 'dequeueWsEvents',
                    });
                    newCall.setCallId(callId);
                    if (mobiusEvent.data.broadworksCorrelationInfo) {
                        log.log(`Found broadworksCorrelationInfo: ${mobiusEvent.data.broadworksCorrelationInfo}`, {
                            file: CALL_MANAGER_FILE,
                            method: 'dequeueWsEvents',
                        });
                        newCall.setBroadworksCorrelationInfo(mobiusEvent.data.broadworksCorrelationInfo);
                    }
                }
                else {
                    log.info(`Found the call Object with a matching callId: ${callId} from our records with correlationId: ${newId}`, {
                        file: CALL_MANAGER_FILE,
                        method: 'dequeueWsEvents',
                    });
                    newCall = this.getCall(newId);
                }
                if (mobiusEvent.data.callerId) {
                    log.info('Processing Caller-Id data', {
                        file: CALL_MANAGER_FILE,
                        method: 'dequeueWsEvents',
                    });
                    newCall.startCallerIdResolution(mobiusEvent.data.callerId);
                }
                this.emit(LINE_EVENT_KEYS.INCOMING_CALL, newCall);
                newCall.sendCallStateMachineEvt({ type: 'E_RECV_CALL_SETUP', data: mobiusEvent.data });
                break;
            }
            case MobiusEventType.CALL_PROGRESS: {
                log.log(`Received call progress mobiusEvent for call: ${correlationId}`, {
                    file: CALL_MANAGER_FILE,
                    method: 'dequeueWsEvents',
                });
                const call = this.getCall(correlationId);
                call.sendCallStateMachineEvt({ type: 'E_RECV_CALL_PROGRESS', data: mobiusEvent.data });
                break;
            }
            case MobiusEventType.CALL_MEDIA: {
                log.log(`Received call media mobiusEvent for call: ${correlationId}`, {
                    file: CALL_MANAGER_FILE,
                    method: 'dequeueWsEvents',
                });
                let activeCall;
                if (correlationId) {
                    activeCall = this.getCall(correlationId);
                }
                else {
                    const newId = Object.keys(this.callCollection).find((id) => this.callCollection[id].getCallId() === callId);
                    if (newId) {
                        log.info(`Found the call Object with a matching callId: ${callId} from our records with correlationId: ${newId}`, {
                            file: CALL_MANAGER_FILE,
                            method: 'dequeueWsEvents',
                        });
                        activeCall = this.getCall(newId);
                    }
                    else {
                        const lineId = this.getLineId(mobiusEvent.data.deviceId);
                        activeCall = this.createCall({}, CallDirection.INBOUND, mobiusEvent.data.deviceId, lineId);
                        log.log(`New incoming call created with correlationId from ROAP Message: ${activeCall.getCorrelationId()}`, {
                            file: CALL_MANAGER_FILE,
                            method: 'dequeueWsEvents',
                        });
                        activeCall.setCallId(callId);
                    }
                }
                if (activeCall) {
                    log.info(`SDP from mobius ${mobiusEvent.data.message?.sdp}`, {
                        file: CALL_MANAGER_FILE,
                        method: 'dequeueWsEvents',
                    });
                    log.log(`ROAP message from mobius with type:  ${mobiusEvent.data.message?.messageType}, seq: ${mobiusEvent.data.message?.seq} , version: ${mobiusEvent.data.message?.version}`, {
                        file: CALL_MANAGER_FILE,
                        method: 'dequeueWsEvents',
                    });
                    const mediaState = mobiusEvent.data.message?.messageType;
                    switch (mediaState) {
                        case MediaState.OFFER: {
                            log.log('Received OFFER', {
                                file: CALL_MANAGER_FILE,
                                method: 'dequeueWsEvents',
                            });
                            activeCall.sendMediaStateMachineEvt({
                                type: 'E_RECV_ROAP_OFFER',
                                data: mobiusEvent.data.message,
                            });
                            break;
                        }
                        case MediaState.ANSWER: {
                            log.log('Received ANSWER', {
                                file: CALL_MANAGER_FILE,
                                method: 'dequeueWsEvents',
                            });
                            activeCall.sendMediaStateMachineEvt({
                                type: 'E_RECV_ROAP_ANSWER',
                                data: mobiusEvent.data.message,
                            });
                            break;
                        }
                        case MediaState.OFFER_REQUEST: {
                            log.log('Received OFFER_REQUEST', {
                                file: CALL_MANAGER_FILE,
                                method: 'dequeueWsEvents',
                            });
                            activeCall.sendMediaStateMachineEvt({
                                type: 'E_RECV_ROAP_OFFER_REQUEST',
                                data: mobiusEvent.data.message,
                            });
                            break;
                        }
                        case MediaState.OK: {
                            log.log('Received OK', {
                                file: CALL_MANAGER_FILE,
                                method: 'dequeueWsEvents',
                            });
                            const mediaOk = {
                                received: true,
                                message: mobiusEvent.data.message,
                            };
                            activeCall.sendMediaStateMachineEvt({
                                type: 'E_ROAP_OK',
                                data: mediaOk,
                            });
                            break;
                        }
                        case MediaState.ERROR: {
                            log.log('Received Error...', {
                                file: CALL_MANAGER_FILE,
                                method: 'dequeueWsEvents',
                            });
                            break;
                        }
                        default: {
                            log.log(`Unknown Media mobiusEvent: ${mediaState} `, {
                                file: CALL_MANAGER_FILE,
                                method: 'dequeueWsEvents',
                            });
                        }
                    }
                }
                else {
                    log.log(`CorrelationId: ${correlationId} doesn't exist , discarding..`, {
                        file: CALL_MANAGER_FILE,
                        method: 'dequeueWsEvents',
                    });
                }
                break;
            }
            case MobiusEventType.CALL_CONNECTED: {
                log.log(`Received call connect for call: ${correlationId}`, {
                    file: CALL_MANAGER_FILE,
                    method: 'dequeueWsEvents',
                });
                const call = this.getCall(correlationId);
                call.sendCallStateMachineEvt({ type: 'E_RECV_CALL_CONNECT', data: mobiusEvent.data });
                break;
            }
            case MobiusEventType.CALL_DISCONNECTED: {
                log.log(`Received call disconnect for call: ${correlationId}`, {
                    file: CALL_MANAGER_FILE,
                    method: 'dequeueWsEvents',
                });
                const call = this.getCall(correlationId);
                if (call) {
                    call.sendCallStateMachineEvt({ type: 'E_RECV_CALL_DISCONNECT' });
                }
                break;
            }
            default: {
                log.log(`Unknown Call Event mobiusEvent: ${mobiusEvent.data.eventType}`, {
                    file: CALL_MANAGER_FILE,
                    method: 'dequeueWsEvents',
                });
            }
        }
    }
    getCall = (correlationId) => {
        return this.callCollection[correlationId];
    };
    getActiveCalls = () => {
        return this.callCollection;
    };
    updateLine(deviceId, line) {
        this.lineDict[deviceId] = line;
    }
    getLineId(deviceId) {
        return this.lineDict[deviceId].lineId;
    }
}
export const getCallManager = (webex, indicator) => {
    if (!callManager) {
        callManager = new CallManager(webex, indicator);
    }
    return callManager;
};
