import SDKConnector from '../SDKConnector';
import { RAW_REQUEST, SUCCESS_MESSAGE, SUCCESS_STATUS_CODE, TRANSCRIPT, USER, XML_TYPE, BW_XSI_ENDPOINT_VERSION, WEBEX_CALLING_CONNECTOR_FILE, } from '../common/constants';
import { serviceErrorCodeHandler, getXsiActionEndpoint, getSortedVoicemailList, resolveContact, storeVoicemailList, fetchVoicemailList, } from '../common/Utils';
import { CALLING_BACKEND, HTTP_METHODS, SORT, } from '../common/types';
import log from '../Logger';
import { JSON_FORMAT, MARK_AS_READ, MARK_AS_UNREAD, MESSAGE_MEDIA_CONTENT, TRANSCRIPT_CONTENT, VOICE_MESSAGING_MESSAGES, NO_VOICEMAIL_MSG, NO_VOICEMAIL_STATUS_CODE, RADIX_RAND, PREFIX, TRANSCRIPT_STATUS, MESSAGE_SUMMARY, CALLS, SUMMARY, NEW_MESSAGES, NEW_URGENT_MESSAGES, OLD_URGENT_MESSAGES, OLD_MESSAGES, } from './constants';
export class WxCallBackendConnector {
    xsiEndpoint;
    userId;
    context;
    sdkConnector;
    xsiVoiceMessageURI;
    webex;
    constructor(webex, logger) {
        this.sdkConnector = SDKConnector;
        if (!this.sdkConnector.getWebex()) {
            SDKConnector.setWebex(webex);
        }
        this.context = Math.random().toString(RADIX_RAND).substring(PREFIX);
        this.webex = this.sdkConnector.getWebex();
        this.userId = this.webex.internal.device.userId;
        log.setLogger(logger.level, WEBEX_CALLING_CONNECTOR_FILE);
    }
    init() {
        const loggerContext = {
            file: WEBEX_CALLING_CONNECTOR_FILE,
            method: 'init',
        };
        log.info('Initializing Webex calling voicemail connector', loggerContext);
        const response = this.setXsiVoiceMessageURI();
        return response;
    }
    getSDKConnector() {
        return this.sdkConnector;
    }
    async setXsiVoiceMessageURI() {
        let responseDetails;
        const loggerContext = {
            file: WEBEX_CALLING_CONNECTOR_FILE,
            method: 'setXsiVoiceMessageURI',
        };
        this.xsiEndpoint = await getXsiActionEndpoint(this.webex, loggerContext, CALLING_BACKEND.WXC);
        log.info(`XsiEndpoint is ${this.xsiEndpoint}`, loggerContext);
        if (this.userId) {
            this.xsiVoiceMessageURI = `${this.xsiEndpoint}/${BW_XSI_ENDPOINT_VERSION}/${USER}/${this.userId}/${VOICE_MESSAGING_MESSAGES}`;
            responseDetails = {
                statusCode: SUCCESS_STATUS_CODE,
                data: {},
                message: SUCCESS_MESSAGE,
            };
        }
        return responseDetails;
    }
    async getVoicemailList(offset, offsetLimit, sort, refresh) {
        const loggerContext = {
            file: WEBEX_CALLING_CONNECTOR_FILE,
            method: 'getVoicemailList',
        };
        log.info(`Offset: ${offset} Offset limit: ${offsetLimit} Sort type:${sort}`, loggerContext);
        let messageinfo;
        if (refresh) {
            const urlXsi = `${this.xsiVoiceMessageURI}${JSON_FORMAT}`;
            const sortParam = Object.values(SORT).includes(sort) ? sort : SORT.DEFAULT;
            try {
                const response = await this.webex.request({
                    uri: `${urlXsi}`,
                    method: HTTP_METHODS.GET,
                });
                const voicemailListResponse = response.body;
                if (Object.keys(voicemailListResponse?.VoiceMessagingMessages?.messageInfoList).length === 0) {
                    messageinfo = [];
                }
                else if (!Array.isArray(voicemailListResponse?.VoiceMessagingMessages?.messageInfoList?.messageInfo)) {
                    messageinfo = Array(voicemailListResponse?.VoiceMessagingMessages?.messageInfoList?.messageInfo);
                }
                else {
                    messageinfo = voicemailListResponse?.VoiceMessagingMessages?.messageInfoList
                        ?.messageInfo;
                    messageinfo = getSortedVoicemailList(messageinfo, sortParam);
                }
                storeVoicemailList(this.context, messageinfo);
            }
            catch (err) {
                const errorInfo = err;
                const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
                return errorStatus;
            }
        }
        const { messages, moreVMAvailable } = fetchVoicemailList(this.context, offset, offsetLimit, loggerContext);
        const responseDetails = {
            statusCode: moreVMAvailable ? SUCCESS_STATUS_CODE : NO_VOICEMAIL_STATUS_CODE,
            data: {
                voicemailList: messages,
            },
            message: moreVMAvailable ? SUCCESS_MESSAGE : NO_VOICEMAIL_MSG,
        };
        return responseDetails;
    }
    async getVoicemailContent(messageId) {
        const loggerContext = {
            file: WEBEX_CALLING_CONNECTOR_FILE,
            method: 'getVoicemailContent',
        };
        try {
            const voicemailContentUrl = `${this.xsiEndpoint}${messageId}`;
            const response = await this.webex.request({
                uri: `${voicemailContentUrl}`,
                method: HTTP_METHODS.GET,
            });
            const parser = new DOMParser();
            const xmlDOM = parser.parseFromString(response[RAW_REQUEST].response, XML_TYPE);
            const mediaDetails = xmlDOM.getElementsByTagName(MESSAGE_MEDIA_CONTENT)[0];
            const mediaType = mediaDetails.childNodes[1]?.textContent;
            const mediaContent = mediaDetails.childNodes[2]?.textContent;
            log.info(`Media type is  ${mediaType}`, loggerContext);
            const responseDetails = {
                statusCode: Number(response.statusCode),
                data: {
                    voicemailContent: {
                        type: mediaType,
                        content: mediaContent,
                    },
                },
                message: SUCCESS_MESSAGE,
            };
            return responseDetails;
        }
        catch (err) {
            const errorInfo = err;
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            log.info(`Voice mail content error is ${errorStatus}`, loggerContext);
            return errorStatus;
        }
    }
    async getVoicemailSummary() {
        const loggerContext = {
            file: WEBEX_CALLING_CONNECTOR_FILE,
            method: 'getVoicemailSummary',
        };
        try {
            const voicemailSummaryUrl = `${this.xsiEndpoint}/${BW_XSI_ENDPOINT_VERSION}/${USER}/${this.userId}/${CALLS}/${MESSAGE_SUMMARY}`;
            const response = await this.webex.request({
                uri: `${voicemailSummaryUrl}`,
                method: HTTP_METHODS.GET,
            });
            const parser = new DOMParser();
            const xmlDOM = parser.parseFromString(response[RAW_REQUEST].response, XML_TYPE);
            const voicemailSummary = xmlDOM.getElementsByTagName(SUMMARY)[0];
            const newMessages = voicemailSummary.getElementsByTagName(NEW_MESSAGES)[0];
            const newUrgentMessages = voicemailSummary.getElementsByTagName(NEW_URGENT_MESSAGES)[0];
            const oldMessages = voicemailSummary.getElementsByTagName(OLD_MESSAGES)[0];
            const oldUrgentMessages = voicemailSummary.getElementsByTagName(OLD_URGENT_MESSAGES)[0];
            const responseDetails = {
                statusCode: Number(response.statusCode),
                data: {
                    voicemailSummary: {
                        newMessages: newMessages ? Number(newMessages.textContent) : 0,
                        newUrgentMessages: newUrgentMessages ? Number(newUrgentMessages.textContent) : 0,
                        oldMessages: oldMessages ? Number(oldMessages.textContent) : 0,
                        oldUrgentMessages: oldUrgentMessages ? Number(oldUrgentMessages.textContent) : 0,
                    },
                },
                message: SUCCESS_MESSAGE,
            };
            return responseDetails;
        }
        catch (err) {
            const errorInfo = err;
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            log.error(new Error(`Voicemail summary error is ${errorStatus}`), loggerContext);
            return errorStatus;
        }
    }
    async voicemailMarkAsRead(messageId) {
        const loggerContext = {
            file: WEBEX_CALLING_CONNECTOR_FILE,
            method: 'voicemailMarkAsRead',
        };
        try {
            const voicemailContentUrl = `${this.xsiEndpoint}${messageId}/${MARK_AS_READ}`;
            const response = await this.webex.request({
                uri: voicemailContentUrl,
                method: HTTP_METHODS.PUT,
            });
            const responseDetails = {
                statusCode: Number(response.statusCode),
                data: {},
                message: SUCCESS_MESSAGE,
            };
            return responseDetails;
        }
        catch (err) {
            const errorInfo = err;
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            return errorStatus;
        }
    }
    async voicemailMarkAsUnread(messageId) {
        const loggerContext = {
            file: WEBEX_CALLING_CONNECTOR_FILE,
            method: 'voicemailMarkAsUnread',
        };
        try {
            const voicemailContentUrl = `${this.xsiEndpoint}${messageId}/${MARK_AS_UNREAD}`;
            const response = await this.webex.request({
                uri: voicemailContentUrl,
                method: HTTP_METHODS.PUT,
            });
            const responseDetails = {
                statusCode: Number(response.statusCode),
                data: {},
                message: SUCCESS_MESSAGE,
            };
            return responseDetails;
        }
        catch (err) {
            const errorInfo = err;
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            return errorStatus;
        }
    }
    async deleteVoicemail(messageId) {
        const loggerContext = {
            file: WEBEX_CALLING_CONNECTOR_FILE,
            method: 'deleteVoicemail',
        };
        try {
            const voicemailContentUrl = `${this.xsiEndpoint}${messageId}`;
            const response = await this.webex.request({
                uri: voicemailContentUrl,
                method: HTTP_METHODS.DELETE,
            });
            const responseDetails = {
                statusCode: Number(response.statusCode),
                data: {},
                message: SUCCESS_MESSAGE,
            };
            return responseDetails;
        }
        catch (err) {
            const errorInfo = err;
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            return errorStatus;
        }
    }
    async getVMTranscript(messageId) {
        const loggerContext = {
            file: WEBEX_CALLING_CONNECTOR_FILE,
            method: 'getVMTranscript',
        };
        try {
            const voicemailContentUrl = `${this.xsiEndpoint}${messageId}/${TRANSCRIPT}`;
            const response = await this.webex.request({
                uri: voicemailContentUrl,
                method: HTTP_METHODS.GET,
            });
            const parser = new DOMParser();
            const xmlDOM = parser.parseFromString(response[RAW_REQUEST].response, XML_TYPE);
            const status = xmlDOM.getElementsByTagName(TRANSCRIPT_STATUS)[0];
            const transcript = xmlDOM.getElementsByTagName(TRANSCRIPT_CONTENT)[0];
            const responseDetails = {
                statusCode: Number(response.statusCode),
                data: {
                    voicemailTranscript: transcript?.textContent,
                },
                message: status.textContent,
            };
            return responseDetails;
        }
        catch (err) {
            const errorInfo = err;
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            return errorStatus;
        }
    }
    resolveContact(callingPartyInfo) {
        return resolveContact(callingPartyInfo);
    }
}
