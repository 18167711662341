import SDKConnector from '../SDKConnector';
import log from '../Logger';
import { HTTP_METHODS, DecodeType, CALLING_BACKEND } from '../common/types';
import { SERVICES_ENDPOINT, STATUS_CODE, SUCCESS_MESSAGE, XML_TYPE, WEBEX_CALLING_CONNECTOR_FILE, } from '../common/constants';
import { CALL_SETTINGS_FILE, CALL_WAITING_ENDPOINT, CF_ENDPOINT, DND_ENDPOINT, ORG_ENDPOINT, PEOPLE_ENDPOINT, USER_ENDPOINT, VM_ENDPOINT, XSI_VERSION, } from './constants';
import { getXsiActionEndpoint, inferIdFromUuid, serviceErrorCodeHandler } from '../common/Utils';
export class WxCallBackendConnector {
    sdkConnector;
    webex;
    userId;
    personId;
    orgId;
    xsiEndpoint;
    hydraEndpoint;
    VOICEMAIL = 'VOICEMAIL';
    constructor(webex, logger) {
        this.sdkConnector = SDKConnector;
        if (!this.sdkConnector.getWebex()) {
            SDKConnector.setWebex(webex);
        }
        this.webex = this.sdkConnector.getWebex();
        this.hydraEndpoint = this.webex.internal.services._serviceUrls.hydra;
        log.setLogger(logger.level, WEBEX_CALLING_CONNECTOR_FILE);
        this.userId = this.webex.internal.device.userId;
        this.personId = inferIdFromUuid(this.webex.internal.device.userId, DecodeType.PEOPLE);
        this.orgId = inferIdFromUuid(this.webex.internal.device.orgId, DecodeType.ORGANIZATION);
    }
    async getCallWaitingSetting() {
        const loggerContext = {
            file: CALL_SETTINGS_FILE,
            method: 'getCallWaitingSetting',
        };
        try {
            if (!this.xsiEndpoint) {
                this.xsiEndpoint = await getXsiActionEndpoint(this.webex, loggerContext, CALLING_BACKEND.WXC);
                log.info(`xsiEndpoint: ${this.xsiEndpoint}`, loggerContext);
            }
            const callWaitingUrl = `${this.xsiEndpoint}/${XSI_VERSION}/${USER_ENDPOINT}/${this.userId}/${SERVICES_ENDPOINT}/${CALL_WAITING_ENDPOINT}`;
            const response = await fetch(`${callWaitingUrl}`, {
                method: HTTP_METHODS.GET,
                headers: {
                    Authorization: await this.webex.credentials.getUserToken(),
                },
            });
            if (!response.ok) {
                throw new Error(`${response.status}`);
            }
            const xmlData = await response.text();
            const parser = new DOMParser();
            const xmlDOM = parser.parseFromString(xmlData, XML_TYPE);
            const callWaitingDetails = xmlDOM.getElementsByTagName('active');
            const status = callWaitingDetails[0].childNodes[0].textContent;
            const toggleSetting = {
                enabled: status === 'true',
            };
            const responseDetails = {
                statusCode: 200,
                data: {
                    callSetting: toggleSetting,
                },
                message: SUCCESS_MESSAGE,
            };
            return responseDetails;
        }
        catch (err) {
            const errorInfo = {
                statusCode: err instanceof Error ? Number(err.message) : '',
            };
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            return errorStatus;
        }
    }
    async getDoNotDisturbSetting() {
        const loggerContext = {
            file: CALL_SETTINGS_FILE,
            method: 'getDoNotDisturbSetting',
        };
        try {
            const resp = await this.webex.request({
                uri: `${this.hydraEndpoint}/${PEOPLE_ENDPOINT}/${this.personId}/${DND_ENDPOINT}?${ORG_ENDPOINT}=${this.orgId}`,
                method: HTTP_METHODS.GET,
            });
            const dndSettingResponse = resp.body;
            const responseDetails = {
                statusCode: Number(resp[STATUS_CODE]),
                data: {
                    callSetting: dndSettingResponse,
                },
                message: SUCCESS_MESSAGE,
            };
            return responseDetails;
        }
        catch (err) {
            const errorInfo = err;
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            return errorStatus;
        }
    }
    async setDoNotDisturbSetting(enabled) {
        const loggerContext = {
            file: CALL_SETTINGS_FILE,
            method: 'setDoNotDisturbSetting',
        };
        try {
            const dndRequestBody = {
                enabled,
                ringSplashEnabled: false,
            };
            const resp = await this.webex.request({
                uri: `${this.hydraEndpoint}/${PEOPLE_ENDPOINT}/${this.personId}/${DND_ENDPOINT}?${ORG_ENDPOINT}=${this.orgId}`,
                method: HTTP_METHODS.PUT,
                body: dndRequestBody,
            });
            const responseDetails = {
                statusCode: Number(resp[STATUS_CODE]),
                data: {
                    callSetting: dndRequestBody,
                },
                message: SUCCESS_MESSAGE,
            };
            return responseDetails;
        }
        catch (err) {
            const errorInfo = err;
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            return errorStatus;
        }
    }
    async getCallForwardSetting() {
        const loggerContext = {
            file: CALL_SETTINGS_FILE,
            method: 'getCallForwardingSetting',
        };
        try {
            const resp = await this.webex.request({
                uri: `${this.hydraEndpoint}/${PEOPLE_ENDPOINT}/${this.personId}/${CF_ENDPOINT}?${ORG_ENDPOINT}=${this.orgId}`,
                method: HTTP_METHODS.GET,
            });
            const cfResponse = resp.body;
            const responseDetails = {
                statusCode: Number(resp[STATUS_CODE]),
                data: {
                    callSetting: cfResponse,
                },
                message: SUCCESS_MESSAGE,
            };
            return responseDetails;
        }
        catch (err) {
            const errorInfo = err;
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            return errorStatus;
        }
    }
    async setCallForwardSetting(callForwardingRequest) {
        const loggerContext = {
            file: CALL_SETTINGS_FILE,
            method: 'setCallForwardingSetting',
        };
        try {
            const resp = await this.webex.request({
                uri: `${this.hydraEndpoint}/${PEOPLE_ENDPOINT}/${this.personId}/${CF_ENDPOINT}?${ORG_ENDPOINT}=${this.orgId}`,
                method: HTTP_METHODS.PUT,
                body: callForwardingRequest,
            });
            const responseDetails = {
                statusCode: Number(resp[STATUS_CODE]),
                data: {
                    callSetting: callForwardingRequest,
                },
                message: SUCCESS_MESSAGE,
            };
            return responseDetails;
        }
        catch (err) {
            const errorInfo = err;
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            return errorStatus;
        }
    }
    async getVoicemailSetting() {
        const loggerContext = {
            file: CALL_SETTINGS_FILE,
            method: 'getVoicemailSetting',
        };
        try {
            const resp = await this.webex.request({
                uri: `${this.hydraEndpoint}/${PEOPLE_ENDPOINT}/${this.personId}/${VM_ENDPOINT}?${ORG_ENDPOINT}=${this.orgId}`,
                method: HTTP_METHODS.GET,
            });
            const vmResponse = resp.body;
            const responseDetails = {
                statusCode: Number(resp[STATUS_CODE]),
                data: {
                    callSetting: vmResponse,
                },
                message: SUCCESS_MESSAGE,
            };
            return responseDetails;
        }
        catch (err) {
            const errorInfo = err;
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            return errorStatus;
        }
    }
    async setVoicemailSetting(voicemailRequest) {
        const loggerContext = {
            file: CALL_SETTINGS_FILE,
            method: 'setVoicemailSetting',
        };
        try {
            const resp = await this.webex.request({
                uri: `${this.hydraEndpoint}/${PEOPLE_ENDPOINT}/${this.personId}/${VM_ENDPOINT}?${ORG_ENDPOINT}=${this.orgId}`,
                method: HTTP_METHODS.PUT,
                body: voicemailRequest,
            });
            const responseDetails = {
                statusCode: Number(resp[STATUS_CODE]),
                data: {
                    callSetting: voicemailRequest,
                },
                message: SUCCESS_MESSAGE,
            };
            return responseDetails;
        }
        catch (err) {
            const errorInfo = err;
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            return errorStatus;
        }
    }
    async getCallForwardAlwaysSetting() {
        const loggerContext = {
            file: WEBEX_CALLING_CONNECTOR_FILE,
            method: this.getCallForwardAlwaysSetting.name,
        };
        const cfResponse = await this.getCallForwardSetting();
        if (cfResponse.statusCode === 200) {
            const cfa = cfResponse.data.callSetting.callForwarding.always;
            if (cfa.enabled) {
                if (cfa.destination) {
                    const response = {
                        ...cfResponse,
                        data: {
                            callSetting: cfa,
                        },
                    };
                    return response;
                }
                log.warn(`CFA is enabled, but destination is not set`, loggerContext);
            }
            const vmResponse = await this.getVoicemailSetting();
            if (vmResponse.statusCode === 200) {
                const vm = vmResponse.data.callSetting;
                if (vm.enabled && vm.sendAllCalls.enabled) {
                    const response = {
                        ...cfResponse,
                        data: {
                            callSetting: {
                                ...cfa,
                                enabled: true,
                                destination: this.VOICEMAIL,
                            },
                        },
                    };
                    return response;
                }
                const response = {
                    ...cfResponse,
                    data: {
                        callSetting: {
                            ...cfa,
                            enabled: false,
                            destination: undefined,
                        },
                    },
                };
                return response;
            }
            log.warn(`Unable to retrieve voicemail settings.`, loggerContext);
            vmResponse.data.error = 'Unable to retrieve voicemail settings.';
            return vmResponse;
        }
        return cfResponse;
    }
}
