import { ERROR_TYPE } from '../types';
export default class ExtendedError extends Error {
    type;
    context;
    constructor(msg, context, type) {
        super(msg);
        this.type = type || ERROR_TYPE.DEFAULT;
        this.context = context;
    }
}
