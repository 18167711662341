import { RegistrationStatus } from '../../common/types';
import ExtendedError from './ExtendedError';
export class CallingClientError extends ExtendedError {
    status = RegistrationStatus.INACTIVE;
    constructor(msg, context, type, status) {
        super(msg, context, type);
        this.status = status;
    }
    setError(error) {
        this.message = error.message;
        this.context = error.context;
        this.type = error.type;
    }
    getError() {
        return { message: this.message, context: this.context, type: this.type };
    }
}
export const createClientError = (msg, context, type, status) => new CallingClientError(msg, context, type, status);
