export const validateWebex = (webexInstance) => {
    if (webexInstance.canAuthorize) {
        if (webexInstance.ready) {
            if (webexInstance.internal.mercury) {
                return { error: undefined, success: true };
            }
            return { error: new Error('webex.internal.mercury is not available'), success: false };
        }
        return { error: new Error('webex.ready is not true'), success: false };
    }
    return { error: new Error('webex.canAuthorize is not true'), success: false };
};
