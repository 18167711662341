import SDKConnector from '../SDKConnector';
import { CALLING_BACKEND } from '../common/types';
import log from '../Logger';
import { getCallingBackEnd } from '../common/Utils';
import { WxCallBackendConnector } from './WxCallBackendConnector';
import { BroadworksBackendConnector } from './BroadworksBackendConnector';
import { Eventing } from '../Events/impl';
import { UcmBackendConnector } from './UcmBackendConnector';
import { METRIC_EVENT, METRIC_TYPE, VOICEMAIL_ACTION } from '../Metrics/types';
import { getMetricManager } from '../Metrics';
import { VOICEMAIL_FILE } from './constants';
export class Voicemail extends Eventing {
    logger;
    sdkConnector;
    webex;
    callingBackend;
    backendConnector;
    metricManager;
    constructor(webex, logger) {
        super();
        this.logger = logger;
        this.sdkConnector = SDKConnector;
        if (!this.sdkConnector.getWebex()) {
            SDKConnector.setWebex(webex);
        }
        this.webex = this.sdkConnector.getWebex();
        this.metricManager = getMetricManager(this.webex, undefined);
        this.callingBackend = getCallingBackEnd(this.webex);
        this.initializeBackendConnector();
        log.setLogger(logger.level, VOICEMAIL_FILE);
    }
    init() {
        const response = this.backendConnector.init();
        return response;
    }
    initializeBackendConnector() {
        switch (this.callingBackend) {
            case CALLING_BACKEND.WXC: {
                this.backendConnector = new WxCallBackendConnector(this.webex, this.logger);
                break;
            }
            case CALLING_BACKEND.BWRKS: {
                this.backendConnector = new BroadworksBackendConnector(this.webex, this.logger);
                break;
            }
            case CALLING_BACKEND.UCM: {
                this.backendConnector = new UcmBackendConnector(this.webex, this.logger);
                break;
            }
            default: {
                throw new Error('Calling backend is not identified, exiting....');
            }
        }
    }
    submitMetric(response, metricAction, messageId) {
        const { statusCode, data: { error: errorMessage }, } = response;
        if (statusCode >= 200 && statusCode < 300) {
            this.metricManager.submitVoicemailMetric(METRIC_EVENT.VOICEMAIL, metricAction, METRIC_TYPE.BEHAVIORAL, messageId);
        }
        else {
            this.metricManager.submitVoicemailMetric(METRIC_EVENT.VOICEMAIL_ERROR, metricAction, METRIC_TYPE.BEHAVIORAL, messageId, errorMessage, statusCode);
        }
    }
    async getVoicemailList(offset, offsetLimit, sort, refresh) {
        const response = await this.backendConnector.getVoicemailList(offset, offsetLimit, sort, refresh);
        this.submitMetric(response, VOICEMAIL_ACTION.GET_VOICEMAILS);
        return response;
    }
    async getVoicemailContent(messageId) {
        const response = await this.backendConnector.getVoicemailContent(messageId);
        this.submitMetric(response, VOICEMAIL_ACTION.GET_VOICEMAIL_CONTENT, messageId);
        return response;
    }
    async getVoicemailSummary() {
        const response = await this.backendConnector.getVoicemailSummary();
        if (response !== null) {
            this.submitMetric(response, VOICEMAIL_ACTION.GET_VOICEMAIL_SUMMARY);
        }
        return response;
    }
    async voicemailMarkAsRead(messageId) {
        const response = await this.backendConnector.voicemailMarkAsRead(messageId);
        this.submitMetric(response, VOICEMAIL_ACTION.MARK_READ, messageId);
        return response;
    }
    async voicemailMarkAsUnread(messageId) {
        const response = await this.backendConnector.voicemailMarkAsUnread(messageId);
        this.submitMetric(response, VOICEMAIL_ACTION.MARK_UNREAD, messageId);
        return response;
    }
    async deleteVoicemail(messageId) {
        const response = await this.backendConnector.deleteVoicemail(messageId);
        this.submitMetric(response, VOICEMAIL_ACTION.DELETE, messageId);
        return response;
    }
    async getVMTranscript(messageId) {
        const response = await this.backendConnector.getVMTranscript(messageId);
        if (response !== null) {
            this.submitMetric(response, VOICEMAIL_ACTION.TRANSCRIPT, messageId);
        }
        return response;
    }
    resolveContact(callingPartyInfo) {
        return this.backendConnector.resolveContact(callingPartyInfo);
    }
    getSDKConnector() {
        return this.sdkConnector;
    }
}
export const createVoicemailClient = (webex, logger) => new Voicemail(webex, logger);
