import { METRIC_FILE, VERSION } from '../CallingClient/constants';
import { METRIC_EVENT } from './types';
import log from '../Logger';
let metricManager;
class MetricManager {
    webex;
    deviceInfo;
    serviceIndicator;
    constructor(webex, indicator) {
        log.info('Initializing metric manager...', { file: METRIC_FILE });
        this.webex = webex;
        this.serviceIndicator = indicator;
    }
    setDeviceInfo(deviceInfo) {
        this.deviceInfo = deviceInfo;
    }
    submitRegistrationMetric(name, metricAction, type, clientError) {
        let data;
        switch (name) {
            case METRIC_EVENT.REGISTRATION: {
                data = {
                    tags: {
                        action: metricAction,
                        device_id: this.deviceInfo?.device?.deviceId,
                        service_indicator: this.serviceIndicator,
                    },
                    fields: {
                        device_url: this.deviceInfo?.device?.clientDeviceUri,
                        mobius_url: this.deviceInfo?.device?.uri,
                        calling_sdk_version: VERSION,
                    },
                    type,
                };
                break;
            }
            case METRIC_EVENT.REGISTRATION_ERROR: {
                if (clientError) {
                    data = {
                        tags: {
                            action: metricAction,
                            device_id: this.deviceInfo?.device?.deviceId,
                            service_indicator: this.serviceIndicator,
                        },
                        fields: {
                            device_url: this.deviceInfo?.device?.clientDeviceUri,
                            mobius_url: this.deviceInfo?.device?.uri,
                            calling_sdk_version: VERSION,
                            error: clientError.getError().message,
                            error_type: clientError.getError().type,
                        },
                        type,
                    };
                }
                break;
            }
            default:
                log.warn('Invalid metric name received. Rejecting request to submit metric.', {
                    file: METRIC_FILE,
                    method: this.submitRegistrationMetric.name,
                });
                break;
        }
        if (data) {
            this.webex.internal.metrics.submitClientMetrics(name, data);
        }
    }
    submitCallMetric(name, metricAction, type, callId, correlationId, callError) {
        let data;
        switch (name) {
            case METRIC_EVENT.CALL: {
                data = {
                    tags: {
                        action: metricAction,
                        device_id: this.deviceInfo?.device?.deviceId,
                        service_indicator: this.serviceIndicator,
                    },
                    fields: {
                        device_url: this.deviceInfo?.device?.clientDeviceUri,
                        mobius_url: this.deviceInfo?.device?.uri,
                        calling_sdk_version: VERSION,
                        call_id: callId,
                        correlation_id: correlationId,
                    },
                    type,
                };
                break;
            }
            case METRIC_EVENT.CALL_ERROR: {
                if (callError) {
                    data = {
                        tags: {
                            action: metricAction,
                            device_id: this.deviceInfo?.device?.deviceId,
                            service_indicator: this.serviceIndicator,
                        },
                        fields: {
                            device_url: this.deviceInfo?.device?.clientDeviceUri,
                            mobius_url: this.deviceInfo?.device?.uri,
                            calling_sdk_version: VERSION,
                            call_id: callId,
                            correlation_id: correlationId,
                            error: callError.getCallError().message,
                            error_type: callError.getCallError().type,
                        },
                        type,
                    };
                }
                break;
            }
            default:
                log.warn('Invalid metric name received. Rejecting request to submit metric.', {
                    file: METRIC_FILE,
                    method: this.submitCallMetric.name,
                });
                break;
        }
        if (data) {
            this.webex.internal.metrics.submitClientMetrics(name, data);
        }
    }
    submitMediaMetric(name, metricAction, type, callId, correlationId, localSdp, remoteSdp, callError) {
        let data;
        switch (name) {
            case METRIC_EVENT.MEDIA: {
                data = {
                    tags: {
                        action: metricAction,
                        device_id: this.deviceInfo?.device?.deviceId,
                        service_indicator: this.serviceIndicator,
                    },
                    fields: {
                        device_url: this.deviceInfo?.device?.clientDeviceUri,
                        mobius_url: this.deviceInfo?.device?.uri,
                        calling_sdk_version: VERSION,
                        call_id: callId,
                        correlation_id: correlationId,
                        local_media_details: localSdp,
                        remote_media_details: remoteSdp,
                    },
                    type,
                };
                break;
            }
            case METRIC_EVENT.MEDIA_ERROR: {
                if (callError) {
                    data = {
                        tags: {
                            action: metricAction,
                            device_id: this.deviceInfo?.device?.deviceId,
                            service_indicator: this.serviceIndicator,
                        },
                        fields: {
                            device_url: this.deviceInfo?.device?.clientDeviceUri,
                            mobius_url: this.deviceInfo?.device?.uri,
                            calling_sdk_version: VERSION,
                            call_id: callId,
                            correlation_id: correlationId,
                            local_media_details: localSdp,
                            remote_media_details: remoteSdp,
                            error: callError.getCallError().message,
                            error_type: callError.getCallError().type,
                        },
                        type,
                    };
                }
                break;
            }
            default:
                log.warn('Invalid metric name received. Rejecting request to submit metric.', {
                    file: METRIC_FILE,
                    method: this.submitMediaMetric.name,
                });
                break;
        }
        if (data) {
            this.webex.internal.metrics.submitClientMetrics(name, data);
        }
    }
    submitVoicemailMetric(name, metricAction, type, messageId, voicemailError, statusCode) {
        let data;
        switch (name) {
            case METRIC_EVENT.VOICEMAIL: {
                data = {
                    tags: {
                        action: metricAction,
                        device_id: this.deviceInfo?.device?.deviceId,
                        message_id: messageId,
                    },
                    fields: {
                        device_url: this.deviceInfo?.device?.clientDeviceUri,
                        calling_sdk_version: VERSION,
                    },
                    type,
                };
                break;
            }
            case METRIC_EVENT.VOICEMAIL_ERROR: {
                data = {
                    tags: {
                        action: metricAction,
                        device_id: this.deviceInfo?.device?.deviceId,
                        message_id: messageId,
                        error: voicemailError,
                        status_code: statusCode,
                    },
                    fields: {
                        device_url: this.deviceInfo?.device?.clientDeviceUri,
                        calling_sdk_version: VERSION,
                    },
                    type,
                };
                break;
            }
            default:
                log.warn('Invalid metric name received. Rejecting request to submit metric.', {
                    file: METRIC_FILE,
                    method: this.submitVoicemailMetric.name,
                });
                break;
        }
        if (data) {
            this.webex.internal.metrics.submitClientMetrics(name, data);
        }
    }
    submitBNRMetric(name, type, callId, correlationId) {
        let data;
        if (name === METRIC_EVENT.BNR_ENABLED || name === METRIC_EVENT.BNR_DISABLED) {
            data = {
                tags: {
                    device_id: this.deviceInfo?.device?.deviceId,
                    service_indicator: this.serviceIndicator,
                },
                fields: {
                    device_url: this.deviceInfo?.device?.clientDeviceUri,
                    mobius_url: this.deviceInfo?.device?.uri,
                    calling_sdk_version: VERSION,
                    call_id: callId,
                    correlation_id: correlationId,
                },
                type,
            };
        }
        else {
            log.warn('Invalid metric name received. Rejecting request to submit metric.', {
                file: METRIC_FILE,
                method: this.submitBNRMetric.name,
            });
        }
        if (data) {
            this.webex.internal.metrics.submitClientMetrics(name, data);
        }
    }
}
export const getMetricManager = (webex, indicator) => {
    if (!metricManager) {
        metricManager = new MetricManager(webex, indicator);
    }
    return metricManager;
};
export default getMetricManager;
