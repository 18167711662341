export const BASE64 = 'base64';
export const BEARER = 'bearer';
export const BINARY = 'binary';
export const CONTENT = 'content';
export const DEVICES = 'devices';
export const FAILURE_MESSAGE = 'FAILURE';
export const ITEMS = 'items';
export const KEY = 'key';
export const OBJECT = 'object';
export const PLACEHOLDER_KEY = '$';
export const RAW_REQUEST = 'rawRequest';
export const RESPONSE = 'response';
export const RESPONSE_DATA = 'responseData';
export const RESPONSE_MESSAGE = 'responseMessage';
export const SETTINGS = 'settings';
export const STATUS_CODE = 'statusCode';
export const SUCCESS_MESSAGE = 'SUCCESS';
export const SUCCESS_STATUS_CODE = 200;
export const UNPROCESSABLE_CONTENT_CODE = 422;
export const TIME = 'time';
export const TOKEN = 'token';
export const TRANSCRIPT = 'transcript';
export const USER = 'user';
export const USERS = 'users';
export const USER_SESSIONS = 'userSessions';
export const VALUES = '_values';
export const XML_TYPE = 'text/xml';
export const XSI_ACTION_ENDPOINT = 'xsiActionsEndpoint';
export const XSI_ACTION_ENDPOINT_ORG_URL_PARAM = 'organizations?callingData=true';
export const SERVICES_ENDPOINT = 'services';
export const INFER_ID_CONSTANT = 'ciscospark://us';
export const ENTITLEMENT_BASIC = 'bc-sp-basic';
export const ENTITLEMENT_BROADWORKS_CONN = 'broadworks-connector';
export const ENTITLEMENT_STANDARD = 'bc-sp-standard';
export const NATIVE_SIP_CALL_TO_UCM = 'NATIVE_SIP_CALL_TO_UCM';
export const NATIVE_WEBEX_TEAMS_CALLING = 'NATIVE_WEBEX_TEAMS_CALLING';
export const BW_XSI_ENDPOINT_VERSION = 'v2.0';
export const BW_XSI_URL = 'broadworksXsiActionsUrl';
export const WEBEX_CALLING_CONNECTOR_FILE = 'WxCallBackendConnector';
export const UCM_CONNECTOR_FILE = 'UcmBackendConnector';
export const VOICEMAIL = 'VOICEMAIL';
