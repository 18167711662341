import SDKConnector from '../SDKConnector';
import { HTTP_METHODS, CALLING_BACKEND, } from '../common/types';
import { getVgActionEndpoint, serviceErrorCodeHandler } from '../common/Utils';
import { SUCCESS_MESSAGE, USERS, CONTENT, UCM_CONNECTOR_FILE, FAILURE_MESSAGE, } from '../common/constants';
import log from '../Logger';
import { API_V1, LIMIT, OFFSET, SORT_ORDER, VMGATEWAY, VOICEMAILS } from './constants';
export class UcmBackendConnector {
    vgEndpoint;
    userId;
    orgId;
    sdkConnector;
    webex;
    vgVoiceMessageURI;
    constructor(webex, logger) {
        this.sdkConnector = SDKConnector;
        if (!this.sdkConnector.getWebex()) {
            SDKConnector.setWebex(webex);
        }
        this.webex = this.sdkConnector.getWebex();
        this.userId = this.webex.internal.device.userId;
        this.orgId = this.webex.internal.device.orgId;
        log.setLogger(logger.level, UCM_CONNECTOR_FILE);
    }
    init() {
        const loggerContext = {
            file: UCM_CONNECTOR_FILE,
            method: 'init',
        };
        log.info('Initializing UCM calling voicemail connector', loggerContext);
        const response = this.setUcmVoiceMessageBaseURI();
        return response;
    }
    getSDKConnector() {
        return this.sdkConnector;
    }
    setUcmVoiceMessageBaseURI() {
        this.vgEndpoint = getVgActionEndpoint(this.webex, CALLING_BACKEND.UCM);
        this.vgVoiceMessageURI = `${this.vgEndpoint}/${VMGATEWAY}/${API_V1}/${USERS}/${this.userId}/`;
        return this.vgVoiceMessageURI;
    }
    async getVoicemailList(offset, offsetLimit, sort) {
        const loggerContext = {
            file: UCM_CONNECTOR_FILE,
            method: 'getVoicemailList',
        };
        log.info(`Offset: ${offset} Offset limit: ${offsetLimit} Sort type:${sort}`, loggerContext);
        const urlVg = `${this.vgVoiceMessageURI}${VOICEMAILS}/${OFFSET}=${offset}${LIMIT}=${offsetLimit}${SORT_ORDER}=${sort}`;
        try {
            const response = await this.webex.request({
                uri: `${urlVg}`,
                method: HTTP_METHODS.GET,
                headers: {
                    orgId: this.orgId,
                },
            });
            const msgInfo = response.body;
            const messageinfoArray = [];
            const ucmVmMsgInfo = msgInfo.Message;
            ucmVmMsgInfo.forEach((msgInfoObj) => {
                const message = {};
                let stringObj = {};
                const numberObj = {};
                stringObj.$ = msgInfoObj.Duration;
                message.duration = stringObj;
                numberObj.$ = Number(msgInfoObj.ArrivalTime);
                message.time = numberObj;
                stringObj = { $: '' };
                stringObj.$ = msgInfoObj.MsgId;
                message.messageId = stringObj;
                if (msgInfoObj.Read === 'true') {
                    message.read = {};
                }
                const callerIdObj = msgInfoObj.CallerId;
                const callingParty = {};
                stringObj = { $: '' };
                stringObj.$ = callerIdObj.CallerName;
                callingParty.name = stringObj;
                stringObj = { $: '' };
                stringObj.$ = this.userId;
                callingParty.userId = stringObj;
                stringObj = { $: '' };
                stringObj.$ = callerIdObj.CallerNumber;
                callingParty.address = stringObj;
                message.callingPartyInfo = callingParty;
                messageinfoArray.push(message);
            });
            const responseDetails = {
                statusCode: Number(response.statusCode),
                data: {
                    voicemailList: messageinfoArray,
                },
                message: SUCCESS_MESSAGE,
            };
            return responseDetails;
        }
        catch (err) {
            const errorInfo = err;
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            return errorStatus;
        }
    }
    async getVoicemailContent(messageId) {
        const loggerContext = {
            file: UCM_CONNECTOR_FILE,
            method: 'getVoicemailContent',
        };
        try {
            const response = (await this.getVoicemailContentUcm(messageId));
            return response;
        }
        catch (err) {
            const errorInfo = err;
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            log.info(`Voice mail content error is ${errorStatus}`, loggerContext);
            return errorStatus;
        }
    }
    async getVoicemailSummary() {
        return Promise.resolve(null);
    }
    async getVoicemailContentUcm(messageId) {
        return new Promise((resolve, reject) => {
            const voicemailContentUrl = `${this.vgVoiceMessageURI}${VOICEMAILS}/${messageId}/${CONTENT}`;
            const mercuryApi = `${this.webex.internal.services._serviceUrls.mercuryApi}`;
            this.returnUcmPromise(voicemailContentUrl, mercuryApi)
                .then((response) => {
                if (response.statusCode === 200) {
                    resolve(response);
                }
                else if (response.statusCode === 202) {
                    this.sdkConnector.registerListener('event:ucm.voicemail_download_complete', async (event) => {
                        const responseEvent = event;
                        const voicemailContentUrl = `${this.vgVoiceMessageURI}${VOICEMAILS}/${responseEvent?.data?.messageId}/${CONTENT}`;
                        const response = await this.returnUcmPromise(voicemailContentUrl, mercuryApi);
                        if (response.statusCode === 200) {
                            this.sdkConnector.unregisterListener('event:ucm.voicemail_download_complete');
                            resolve(response);
                        }
                        else {
                            this.sdkConnector.unregisterListener('event:ucm.voicemail_download_complete');
                            reject(response);
                        }
                    });
                }
                else {
                    reject(response);
                }
            })
                .catch((err) => {
                reject(err);
            });
        });
    }
    async returnUcmPromise(voicemailContentUrl, mercuryApi) {
        const response = await this.webex.request({
            uri: `${voicemailContentUrl}`,
            method: HTTP_METHODS.GET,
            headers: {
                orgId: this.orgId,
                deviceUrl: this.webex.internal.device.url,
                mercuryHostname: mercuryApi,
            },
        });
        const contentInfo = response?.body;
        const respHeaders = response.headers;
        const statusCode = response.statusCode;
        const mediaType = respHeaders?.mediatype;
        const mediaContent = contentInfo;
        const responseDetails = {
            statusCode: Number(statusCode),
            data: {
                voicemailContent: {
                    type: mediaType,
                    content: mediaContent,
                },
            },
            message: SUCCESS_MESSAGE,
        };
        if (statusCode !== 200 && statusCode !== 202) {
            responseDetails.message = FAILURE_MESSAGE;
        }
        return responseDetails;
    }
    async voicemailMarkAsRead(messageId) {
        const loggerContext = {
            file: UCM_CONNECTOR_FILE,
            method: 'voicemailMarkAsRead',
        };
        try {
            const voicemailContentUrl = `${this.vgVoiceMessageURI}${VOICEMAILS}/${messageId}`;
            const response = await this.webex.request({
                uri: voicemailContentUrl,
                method: HTTP_METHODS.PUT,
                headers: {
                    orgId: this.orgId,
                },
                body: {
                    read: 'true',
                },
            });
            const responseDetails = {
                statusCode: Number(response.statusCode),
                data: {},
                message: SUCCESS_MESSAGE,
            };
            return responseDetails;
        }
        catch (err) {
            const errorInfo = err;
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            return errorStatus;
        }
    }
    async voicemailMarkAsUnread(messageId) {
        const loggerContext = {
            file: UCM_CONNECTOR_FILE,
            method: 'voicemailMarkAsUnread',
        };
        try {
            const voicemailContentUrl = `${this.vgVoiceMessageURI}${VOICEMAILS}/${messageId}`;
            const response = await this.webex.request({
                uri: voicemailContentUrl,
                method: HTTP_METHODS.PUT,
                headers: {
                    orgId: this.orgId,
                },
                body: {
                    read: 'false',
                },
            });
            const responseDetails = {
                statusCode: Number(response.statusCode),
                data: {},
                message: SUCCESS_MESSAGE,
            };
            return responseDetails;
        }
        catch (err) {
            const errorInfo = err;
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            return errorStatus;
        }
    }
    async deleteVoicemail(messageId) {
        const loggerContext = {
            file: UCM_CONNECTOR_FILE,
            method: 'deleteVoicemail',
        };
        try {
            const voicemailContentUrl = `${this.vgVoiceMessageURI}${VOICEMAILS}/${messageId}`;
            const response = await this.webex.request({
                uri: voicemailContentUrl,
                method: HTTP_METHODS.DELETE,
                headers: {
                    orgId: this.orgId,
                },
            });
            const responseDetails = {
                statusCode: Number(response.statusCode),
                data: {},
                message: SUCCESS_MESSAGE,
            };
            return responseDetails;
        }
        catch (err) {
            const errorInfo = err;
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            return errorStatus;
        }
    }
    async getVMTranscript(messageId) {
        log.info(`Message Id: ${messageId}`, {});
        return Promise.resolve(null);
    }
    resolveContact(callingPartyInfo) {
        log.info(`Calling Party Info: ${callingPartyInfo}`, {});
        return Promise.resolve(null);
    }
}
