import { validateWebex } from './utils';
let instance;
let webex;
class SDKConnector {
    setWebex(webexInstance) {
        if (instance) {
            throw new Error('You cannot set the SDKConnector instance more than once');
        }
        const { error, success } = validateWebex(webexInstance);
        if (error) {
            throw error;
        }
        else if (success) {
            webex = webexInstance;
        }
        else {
            throw new Error('An unknown error occurred setting up the webex instance.');
        }
        instance = this;
    }
    get() {
        return instance;
    }
    getWebex() {
        return webex;
    }
    request(request) {
        return instance.getWebex().request(request);
    }
    registerListener(event, cb) {
        instance.getWebex().internal.mercury.on(event, (data) => {
            cb(data);
        });
    }
    unregisterListener(event) {
        instance.getWebex().internal.mercury.off(event);
    }
}
export default Object.freeze(new SDKConnector());
