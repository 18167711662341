import { LINE_EVENTS } from '../CallingClient/line/types';
export var COMMON_EVENT_KEYS;
(function (COMMON_EVENT_KEYS) {
    COMMON_EVENT_KEYS["CB_VOICEMESSAGE_CONTENT_GET"] = "call_back_voicemail_content_get";
    COMMON_EVENT_KEYS["CALL_HISTORY_USER_SESSION_INFO"] = "callHistory:user_recent_sessions";
    COMMON_EVENT_KEYS["CALL_HISTORY_USER_VIEWED_SESSIONS"] = "callHistory:user_viewed_sessions";
})(COMMON_EVENT_KEYS || (COMMON_EVENT_KEYS = {}));
export var LINE_EVENT_KEYS;
(function (LINE_EVENT_KEYS) {
    LINE_EVENT_KEYS["INCOMING_CALL"] = "incoming_call";
})(LINE_EVENT_KEYS || (LINE_EVENT_KEYS = {}));
export var CALLING_CLIENT_EVENT_KEYS;
(function (CALLING_CLIENT_EVENT_KEYS) {
    CALLING_CLIENT_EVENT_KEYS["ERROR"] = "callingClient:error";
    CALLING_CLIENT_EVENT_KEYS["OUTGOING_CALL"] = "callingClient:outgoing_call";
    CALLING_CLIENT_EVENT_KEYS["USER_SESSION_INFO"] = "callingClient:user_recent_sessions";
    CALLING_CLIENT_EVENT_KEYS["ALL_CALLS_CLEARED"] = "callingClient:all_calls_cleared";
})(CALLING_CLIENT_EVENT_KEYS || (CALLING_CLIENT_EVENT_KEYS = {}));
export var CALL_EVENT_KEYS;
(function (CALL_EVENT_KEYS) {
    CALL_EVENT_KEYS["ALERTING"] = "alerting";
    CALL_EVENT_KEYS["CALL_ERROR"] = "call_error";
    CALL_EVENT_KEYS["CALLER_ID"] = "caller_id";
    CALL_EVENT_KEYS["CONNECT"] = "connect";
    CALL_EVENT_KEYS["DISCONNECT"] = "disconnect";
    CALL_EVENT_KEYS["ESTABLISHED"] = "established";
    CALL_EVENT_KEYS["HELD"] = "held";
    CALL_EVENT_KEYS["HOLD_ERROR"] = "hold_error";
    CALL_EVENT_KEYS["PROGRESS"] = "progress";
    CALL_EVENT_KEYS["REMOTE_MEDIA"] = "remote_media";
    CALL_EVENT_KEYS["RESUME_ERROR"] = "resume_error";
    CALL_EVENT_KEYS["RESUMED"] = "resumed";
    CALL_EVENT_KEYS["TRANSFER_ERROR"] = "transfer_error";
})(CALL_EVENT_KEYS || (CALL_EVENT_KEYS = {}));
export var SUPPLEMENTARY_SERVICES;
(function (SUPPLEMENTARY_SERVICES) {
    SUPPLEMENTARY_SERVICES["HOLD"] = "hold";
    SUPPLEMENTARY_SERVICES["RESUME"] = "resume";
    SUPPLEMENTARY_SERVICES["DIVERT"] = "divert";
    SUPPLEMENTARY_SERVICES["TRANSFER"] = "transfer";
    SUPPLEMENTARY_SERVICES["PARK"] = "park";
})(SUPPLEMENTARY_SERVICES || (SUPPLEMENTARY_SERVICES = {}));
export var MOBIUS_MIDCALL_STATE;
(function (MOBIUS_MIDCALL_STATE) {
    MOBIUS_MIDCALL_STATE["HELD"] = "HELD";
    MOBIUS_MIDCALL_STATE["CONNECTED"] = "CONNECTED";
})(MOBIUS_MIDCALL_STATE || (MOBIUS_MIDCALL_STATE = {}));
export var Disposition;
(function (Disposition) {
    Disposition["ANSWERED"] = "Answered";
    Disposition["CANCELED"] = "Canceled";
    Disposition["INITIATED"] = "Initiated";
    Disposition["MISSED"] = "MISSED";
})(Disposition || (Disposition = {}));
export var SessionType;
(function (SessionType) {
    SessionType["SPARK"] = "SPARK";
    SessionType["WEBEX_CALLING"] = "WEBEXCALLING";
})(SessionType || (SessionType = {}));
export var MOBIUS_EVENT_KEYS;
(function (MOBIUS_EVENT_KEYS) {
    MOBIUS_EVENT_KEYS["SERVER_EVENT_INCLUSIVE"] = "event:mobius";
    MOBIUS_EVENT_KEYS["CALL_SESSION_EVENT_INCLUSIVE"] = "event:janus.user_recent_sessions";
    MOBIUS_EVENT_KEYS["CALL_SESSION_EVENT_LEGACY"] = "event:janus.user_sessions";
    MOBIUS_EVENT_KEYS["CALL_SESSION_EVENT_VIEWED"] = "event:janus.user_viewed_sessions";
})(MOBIUS_EVENT_KEYS || (MOBIUS_EVENT_KEYS = {}));
export var MEDIA_CONNECTION_EVENT_KEYS;
(function (MEDIA_CONNECTION_EVENT_KEYS) {
    MEDIA_CONNECTION_EVENT_KEYS["ROAP_MESSAGE_TO_SEND"] = "roap:messageToSend";
    MEDIA_CONNECTION_EVENT_KEYS["MEDIA_TYPE_AUDIO"] = "audio";
})(MEDIA_CONNECTION_EVENT_KEYS || (MEDIA_CONNECTION_EVENT_KEYS = {}));
var CALL_STATE;
(function (CALL_STATE) {
    CALL_STATE["HELD"] = "held";
    CALL_STATE["REMOTE_HELD"] = "remoteheld";
    CALL_STATE["CONNECTED"] = "connected";
})(CALL_STATE || (CALL_STATE = {}));
export const WEBSOCKET_SCOPE = 'mobius';
export var WEBSOCKET_KEYS;
(function (WEBSOCKET_KEYS) {
    WEBSOCKET_KEYS["CALL_PROGRESS"] = "callprogress";
    WEBSOCKET_KEYS["CALL_CONNECTED"] = "callconnected";
    WEBSOCKET_KEYS["CALL_DISCONNECTED"] = "callconnected";
    WEBSOCKET_KEYS["CALL_INFO"] = "callinfo";
    WEBSOCKET_KEYS["CALL"] = "call";
    WEBSOCKET_KEYS["ROAP"] = "ROAP";
})(WEBSOCKET_KEYS || (WEBSOCKET_KEYS = {}));
