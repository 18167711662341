export var LINE_EVENTS;
(function (LINE_EVENTS) {
    LINE_EVENTS["CONNECTING"] = "connecting";
    LINE_EVENTS["ERROR"] = "error";
    LINE_EVENTS["RECONNECTED"] = "reconnected";
    LINE_EVENTS["RECONNECTING"] = "reconnecting";
    LINE_EVENTS["REGISTERED"] = "registered";
    LINE_EVENTS["UNREGISTERED"] = "unregistered";
    LINE_EVENTS["INCOMING_CALL"] = "line:incoming_call";
})(LINE_EVENTS || (LINE_EVENTS = {}));
