import { getCallingBackEnd } from '../common/Utils';
import SDKConnector from '../SDKConnector';
import log from '../Logger';
import { CALLING_BACKEND } from '../common/types';
import { WxCallBackendConnector } from './WxCallBackendConnector';
import { CALL_SETTINGS_FILE } from './constants';
import { UcmBackendConnector } from './UcmBackendConnector';
export class CallSettings {
    sdkConnector;
    webex;
    callingBackend;
    backendConnector;
    constructor(webex, logger, useProdWebexApis) {
        this.sdkConnector = SDKConnector;
        if (!this.sdkConnector.getWebex()) {
            SDKConnector.setWebex(webex);
        }
        log.setLogger(logger.level, CALL_SETTINGS_FILE);
        this.webex = this.sdkConnector.getWebex();
        this.initializeBackendConnector(logger, useProdWebexApis);
    }
    initializeBackendConnector(logger, useProdWebexApis) {
        this.callingBackend = getCallingBackEnd(this.webex);
        log.info(`Initializing Connector for ${this.callingBackend} backend`, {
            file: CALL_SETTINGS_FILE,
            method: this.initializeBackendConnector.name,
        });
        switch (this.callingBackend) {
            case CALLING_BACKEND.BWRKS:
            case CALLING_BACKEND.WXC:
                this.backendConnector = new WxCallBackendConnector(this.webex, logger);
                break;
            case CALLING_BACKEND.UCM:
                this.backendConnector = new UcmBackendConnector(this.webex, logger, useProdWebexApis);
                break;
            default:
                throw new Error('Calling backend is not identified, exiting....');
        }
    }
    async getCallWaitingSetting() {
        return this.backendConnector.getCallWaitingSetting();
    }
    async getDoNotDisturbSetting() {
        return this.backendConnector.getDoNotDisturbSetting();
    }
    async setDoNotDisturbSetting(enabled) {
        return this.backendConnector.setDoNotDisturbSetting(enabled);
    }
    async getCallForwardSetting() {
        return this.backendConnector.getCallForwardSetting();
    }
    async setCallForwardSetting(callForwardingRequest) {
        return this.backendConnector.setCallForwardSetting(callForwardingRequest);
    }
    async getVoicemailSetting() {
        return this.backendConnector.getVoicemailSetting();
    }
    async setVoicemailSetting(voicemailRequest) {
        return this.backendConnector.setVoicemailSetting(voicemailRequest);
    }
    async getCallForwardAlwaysSetting(directoryNumber) {
        return this.backendConnector.getCallForwardAlwaysSetting(directoryNumber);
    }
}
export const createCallSettingsClient = (webex, logger, useProdWebexApis) => new CallSettings(webex, logger, useProdWebexApis);
