export var LOG_PREFIX;
(function (LOG_PREFIX) {
    LOG_PREFIX["MAIN"] = "CALLING_SDK";
    LOG_PREFIX["FILE"] = "file";
    LOG_PREFIX["METHOD"] = "method";
    LOG_PREFIX["EVENT"] = "event";
    LOG_PREFIX["MESSAGE"] = "message";
    LOG_PREFIX["ERROR"] = "error";
})(LOG_PREFIX || (LOG_PREFIX = {}));
export var LOGGING_LEVEL;
(function (LOGGING_LEVEL) {
    LOGGING_LEVEL[LOGGING_LEVEL["error"] = 1] = "error";
    LOGGING_LEVEL[LOGGING_LEVEL["warn"] = 2] = "warn";
    LOGGING_LEVEL[LOGGING_LEVEL["log"] = 3] = "log";
    LOGGING_LEVEL[LOGGING_LEVEL["info"] = 4] = "info";
    LOGGING_LEVEL[LOGGING_LEVEL["trace"] = 5] = "trace";
})(LOGGING_LEVEL || (LOGGING_LEVEL = {}));
export var LOGGER;
(function (LOGGER) {
    LOGGER["ERROR"] = "error";
    LOGGER["WARN"] = "warn";
    LOGGER["INFO"] = "info";
    LOGGER["LOG"] = "log";
    LOGGER["TRACE"] = "trace";
})(LOGGER || (LOGGER = {}));
