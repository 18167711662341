import { RegistrationStatus } from '../../common/types';
import ExtendedError from './ExtendedError';
export class LineError extends ExtendedError {
    status = RegistrationStatus.INACTIVE;
    constructor(msg, context, type, status) {
        super(msg, context, type);
        this.status = status;
    }
    setError(error) {
        this.message = error.message;
        this.context = error.context;
        this.type = error.type;
        this.status = error.status;
    }
    getError() {
        return {
            message: this.message,
            context: this.context,
            type: this.type,
            status: this.status,
        };
    }
}
export const createLineError = (msg, context, type, status) => new LineError(msg, context, type, status);
