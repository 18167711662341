import log from '../../../Logger/index';
import { CALLER_ID_FILE, FETCH_NAME, VALID_PHONE } from '../../constants';
import SDKConnector from '../../../SDKConnector';
import { resolveCallerIdDisplay } from '../../../common';
export class CallerId {
    webex;
    callerInfo;
    sdkConnector;
    emitter;
    constructor(webex, emitter) {
        this.sdkConnector = SDKConnector;
        this.callerInfo = {};
        if (!this.sdkConnector.getWebex()) {
            SDKConnector.setWebex(webex);
        }
        this.webex = this.sdkConnector.getWebex();
        this.emitter = emitter;
    }
    async resolveCallerId(filter) {
        const displayResult = (await resolveCallerIdDisplay(filter));
        let isChanged = false;
        if (displayResult.name && this.callerInfo.name !== displayResult.name) {
            log.info('Updating Name after resolution', {
                file: CALLER_ID_FILE,
                method: 'resolveCallerId',
            });
            this.callerInfo.name = displayResult.name;
            isChanged = true;
        }
        if (displayResult.num && this.callerInfo.num !== displayResult.num) {
            log.info('Updating Number after resolution', {
                file: CALLER_ID_FILE,
                method: 'resolveCallerId',
            });
            this.callerInfo.num = displayResult.num;
            isChanged = true;
        }
        if (!this.callerInfo.avatarSrc || this.callerInfo.avatarSrc !== displayResult.avatarSrc) {
            log.info('Updating Avatar Id after resolution', {
                file: CALLER_ID_FILE,
                method: 'resolveCallerId',
            });
            this.callerInfo.avatarSrc = displayResult.avatarSrc;
            isChanged = true;
        }
        if (!this.callerInfo.id || this.callerInfo.id !== displayResult.id) {
            log.info('Updating User Id after resolution', {
                file: CALLER_ID_FILE,
                method: 'resolveCallerId',
            });
            this.callerInfo.id = displayResult.id;
            isChanged = true;
        }
        if (isChanged) {
            this.emitter(this.callerInfo);
        }
    }
    async parseRemotePartyInfo(data) {
        const lastToken = data.split(';').slice(-1)[0];
        if (lastToken.includes('externalId')) {
            const externalId = lastToken.split('=')[1];
            log.info(`externalId retrieved: ${externalId}`, {
                file: CALLER_ID_FILE,
                method: 'parseRemotePartyInfo',
            });
            this.resolveCallerId(`id eq "${externalId}"`);
        }
        else {
            log.warn(`externalId not found!`, {
                file: CALLER_ID_FILE,
                method: 'parseRemotePartyInfo',
            });
        }
    }
    parseSipUri(paid) {
        const result = {};
        const data = paid.split('@')[0].replace(/"/g, '');
        const nameMatch = FETCH_NAME.exec(data);
        const num = data.substring(data.indexOf(':') + 1, data.length);
        if (nameMatch) {
            result.name = nameMatch[0].trimEnd();
        }
        else {
            log.warn(`Name field not found!`, {
                file: CALLER_ID_FILE,
                method: 'parseSipUri',
            });
        }
        const phoneMatch = num.match(VALID_PHONE);
        if (phoneMatch && phoneMatch[0].length === num.length) {
            result.num = num;
        }
        else {
            log.warn(`Number field not found!`, {
                file: CALLER_ID_FILE,
                method: 'parseSipUri',
            });
        }
        return result;
    }
    fetchCallerDetails(callerId) {
        this.callerInfo.id = undefined;
        this.callerInfo.avatarSrc = undefined;
        this.callerInfo.name = undefined;
        this.callerInfo.num = undefined;
        if ('p-asserted-identity' in callerId) {
            log.info('Parsing p-asserted-identity within remote party information', {
                file: CALLER_ID_FILE,
                method: 'fetchCallerDetails',
            });
            const result = this.parseSipUri(callerId['p-asserted-identity']);
            this.callerInfo.name = result.name;
            this.callerInfo.num = result.num;
        }
        if (callerId.from) {
            log.info('Parsing from header within the remote party information', {
                file: CALLER_ID_FILE,
                method: 'fetchCallerDetails',
            });
            const result = this.parseSipUri(callerId.from);
            if (!this.callerInfo.name && result.name) {
                log.info('Updating name field from From header', {
                    file: CALLER_ID_FILE,
                    method: 'fetchCallerDetails',
                });
                this.callerInfo.name = result.name;
            }
            if (!this.callerInfo.num && result.num) {
                log.info('Updating number field from From header', {
                    file: CALLER_ID_FILE,
                    method: 'fetchCallerDetails',
                });
                this.callerInfo.num = result.num;
            }
        }
        if (this.callerInfo.name || this.callerInfo.num) {
            this.emitter(this.callerInfo);
        }
        if ('x-broadworks-remote-party-info' in callerId) {
            log.info('Parsing x-broadworks-remote-party-info within remote party information', {
                file: CALLER_ID_FILE,
                method: 'fetchCallerDetails',
            });
            this.parseRemotePartyInfo(callerId['x-broadworks-remote-party-info']);
        }
        return this.callerInfo;
    }
}
export const createCallerId = (webex, emitterCb) => new CallerId(webex, emitterCb);
