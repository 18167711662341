export var ERROR_LAYER;
(function (ERROR_LAYER) {
    ERROR_LAYER["CALL_CONTROL"] = "call_control";
    ERROR_LAYER["MEDIA"] = "media";
})(ERROR_LAYER || (ERROR_LAYER = {}));
export var ERROR_TYPE;
(function (ERROR_TYPE) {
    ERROR_TYPE["CALL_ERROR"] = "call_error";
    ERROR_TYPE["DEFAULT"] = "default_error";
    ERROR_TYPE["FORBIDDEN_ERROR"] = "forbidden";
    ERROR_TYPE["NOT_FOUND"] = "not_found";
    ERROR_TYPE["REGISTRATION_ERROR"] = "registration_error";
    ERROR_TYPE["SERVICE_UNAVAILABLE"] = "service_unavailable";
    ERROR_TYPE["TIMEOUT"] = "timeout";
    ERROR_TYPE["TOKEN_ERROR"] = "token_error";
    ERROR_TYPE["SERVER_ERROR"] = "server_error";
})(ERROR_TYPE || (ERROR_TYPE = {}));
export var ERROR_CODE;
(function (ERROR_CODE) {
    ERROR_CODE[ERROR_CODE["UNAUTHORIZED"] = 401] = "UNAUTHORIZED";
    ERROR_CODE[ERROR_CODE["FORBIDDEN"] = 403] = "FORBIDDEN";
    ERROR_CODE[ERROR_CODE["DEVICE_NOT_FOUND"] = 404] = "DEVICE_NOT_FOUND";
    ERROR_CODE[ERROR_CODE["INTERNAL_SERVER_ERROR"] = 500] = "INTERNAL_SERVER_ERROR";
    ERROR_CODE[ERROR_CODE["NOT_IMPLEMENTED"] = 501] = "NOT_IMPLEMENTED";
    ERROR_CODE[ERROR_CODE["SERVICE_UNAVAILABLE"] = 503] = "SERVICE_UNAVAILABLE";
    ERROR_CODE[ERROR_CODE["BAD_REQUEST"] = 400] = "BAD_REQUEST";
    ERROR_CODE[ERROR_CODE["REQUEST_TIMEOUT"] = 408] = "REQUEST_TIMEOUT";
    ERROR_CODE[ERROR_CODE["TOO_MANY_REQUESTS"] = 429] = "TOO_MANY_REQUESTS";
})(ERROR_CODE || (ERROR_CODE = {}));
export var CALL_ERROR_CODE;
(function (CALL_ERROR_CODE) {
    CALL_ERROR_CODE[CALL_ERROR_CODE["INVALID_STATUS_UPDATE"] = 111] = "INVALID_STATUS_UPDATE";
    CALL_ERROR_CODE[CALL_ERROR_CODE["DEVICE_NOT_REGISTERED"] = 112] = "DEVICE_NOT_REGISTERED";
    CALL_ERROR_CODE[CALL_ERROR_CODE["CALL_NOT_FOUND"] = 113] = "CALL_NOT_FOUND";
    CALL_ERROR_CODE[CALL_ERROR_CODE["ERROR_PROCESSING"] = 114] = "ERROR_PROCESSING";
    CALL_ERROR_CODE[CALL_ERROR_CODE["USER_BUSY"] = 115] = "USER_BUSY";
    CALL_ERROR_CODE[CALL_ERROR_CODE["PARSING_ERROR"] = 116] = "PARSING_ERROR";
    CALL_ERROR_CODE[CALL_ERROR_CODE["TIMEOUT_ERROR"] = 117] = "TIMEOUT_ERROR";
    CALL_ERROR_CODE[CALL_ERROR_CODE["NOT_ACCEPTABLE"] = 118] = "NOT_ACCEPTABLE";
    CALL_ERROR_CODE[CALL_ERROR_CODE["CALL_REJECTED"] = 119] = "CALL_REJECTED";
    CALL_ERROR_CODE[CALL_ERROR_CODE["NOT_AVAILABLE"] = 120] = "NOT_AVAILABLE";
})(CALL_ERROR_CODE || (CALL_ERROR_CODE = {}));
export var DEVICE_ERROR_CODE;
(function (DEVICE_ERROR_CODE) {
    DEVICE_ERROR_CODE[DEVICE_ERROR_CODE["DEVICE_LIMIT_EXCEEDED"] = 101] = "DEVICE_LIMIT_EXCEEDED";
    DEVICE_ERROR_CODE[DEVICE_ERROR_CODE["DEVICE_CREATION_DISABLED"] = 102] = "DEVICE_CREATION_DISABLED";
    DEVICE_ERROR_CODE[DEVICE_ERROR_CODE["DEVICE_CREATION_FAILED"] = 103] = "DEVICE_CREATION_FAILED";
})(DEVICE_ERROR_CODE || (DEVICE_ERROR_CODE = {}));
