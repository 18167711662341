import EventEmitter from 'events';
import Logger from '../../Logger';
import { LOG_PREFIX } from '../../Logger/types';
export class Eventing extends EventEmitter {
    emit(event, ...args) {
        const timestamp = new Date().toUTCString();
        Logger.log(`${timestamp} ${LOG_PREFIX.EVENT}: ${event.toString()} - event emitted with parameters -> ${args} = `, {
            file: 'Events/impl/index.ts',
            method: 'emit',
        });
        return super.emit(event, ...args);
    }
    on(event, listener) {
        return super.on(event, listener);
    }
    off(event, listener) {
        return super.off(event, listener);
    }
}
