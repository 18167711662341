export var ALLOWED_SERVICES;
(function (ALLOWED_SERVICES) {
    ALLOWED_SERVICES["MOBIUS"] = "mobius";
    ALLOWED_SERVICES["JANUS"] = "janus";
})(ALLOWED_SERVICES || (ALLOWED_SERVICES = {}));
export var HTTP_METHODS;
(function (HTTP_METHODS) {
    HTTP_METHODS["GET"] = "GET";
    HTTP_METHODS["POST"] = "POST";
    HTTP_METHODS["PATCH"] = "PATCH";
    HTTP_METHODS["PUT"] = "PUT";
    HTTP_METHODS["DELETE"] = "DELETE";
})(HTTP_METHODS || (HTTP_METHODS = {}));
export var RegistrationStatus;
(function (RegistrationStatus) {
    RegistrationStatus["IDLE"] = "IDLE";
    RegistrationStatus["ACTIVE"] = "active";
    RegistrationStatus["INACTIVE"] = "inactive";
})(RegistrationStatus || (RegistrationStatus = {}));
export var CALLING_BACKEND;
(function (CALLING_BACKEND) {
    CALLING_BACKEND["WXC"] = "WEBEX_CALLING";
    CALLING_BACKEND["BWRKS"] = "BROADWORKS_CALLING";
    CALLING_BACKEND["UCM"] = "UCM_CALLING";
    CALLING_BACKEND["INVALID"] = "Calling backend is currently not supported";
})(CALLING_BACKEND || (CALLING_BACKEND = {}));
export var CallType;
(function (CallType) {
    CallType["URI"] = "uri";
    CallType["TEL"] = "tel";
})(CallType || (CallType = {}));
export var CallDirection;
(function (CallDirection) {
    CallDirection["INBOUND"] = "inbound";
    CallDirection["OUTBOUND"] = "outbound";
})(CallDirection || (CallDirection = {}));
export var SORT;
(function (SORT) {
    SORT["ASC"] = "ASC";
    SORT["DESC"] = "DESC";
    SORT["DEFAULT"] = "DESC";
})(SORT || (SORT = {}));
export var SORT_BY;
(function (SORT_BY) {
    SORT_BY["END_TIME"] = "endTime";
    SORT_BY["DEFAULT"] = "endTime";
    SORT_BY["START_TIME"] = "startTime";
})(SORT_BY || (SORT_BY = {}));
export var ServiceIndicator;
(function (ServiceIndicator) {
    ServiceIndicator["CALLING"] = "calling";
    ServiceIndicator["CONTACT_CENTER"] = "contactcenter";
})(ServiceIndicator || (ServiceIndicator = {}));
export var DecodeType;
(function (DecodeType) {
    DecodeType["PEOPLE"] = "PEOPLE";
    DecodeType["ORGANIZATION"] = "ORGANIZATION";
})(DecodeType || (DecodeType = {}));
