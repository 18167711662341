import { ERROR_CODE } from '../Errors/types';
import SDKConnector from '../SDKConnector';
import { BASE64, BEARER, BINARY, SUCCESS_MESSAGE, SUCCESS_STATUS_CODE, OBJECT, TOKEN, USER, XML_TYPE, BW_XSI_ENDPOINT_VERSION, } from '../common/constants';
import { serviceErrorCodeHandler, getXsiActionEndpoint, getSortedVoicemailList, storeVoicemailList, fetchVoicemailList, } from '../common/Utils';
import { CALLING_BACKEND, HTTP_METHODS, SORT, } from '../common/types';
import log from '../Logger';
import { BROADWORKS_VOICEMAIL_FILE, BW_TOKEN_FETCH_ENDPOINT, JSON_FORMAT, MARK_AS_READ, MARK_AS_UNREAD, MESSAGE_MEDIA_CONTENT, VOICE_MESSAGING_MESSAGES, NO_VOICEMAIL_MSG, NO_VOICEMAIL_STATUS_CODE, RADIX_RAND, PREFIX, } from './constants';
export class BroadworksBackendConnector {
    bwtoken;
    userId;
    xsiAccessToken;
    xsiEndpoint;
    context;
    sdkConnector;
    webex;
    xsiVoiceMessageURI;
    constructor(webex, logger) {
        this.sdkConnector = SDKConnector;
        if (!this.sdkConnector.getWebex()) {
            SDKConnector.setWebex(webex);
        }
        this.webex = this.sdkConnector.getWebex();
        this.context = Math.random().toString(RADIX_RAND).substring(PREFIX);
        log.setLogger(logger.level, BROADWORKS_VOICEMAIL_FILE);
    }
    init() {
        const loggerContext = {
            file: BROADWORKS_VOICEMAIL_FILE,
            method: 'init',
        };
        log.info('Initializing Broadworks voicemail connector', loggerContext);
        const response = this.setXsiVoiceMessageURI();
        return response;
    }
    getSDKConnector() {
        return this.sdkConnector;
    }
    async getUserId() {
        const loggerContext = {
            file: BROADWORKS_VOICEMAIL_FILE,
            method: 'getUserId',
        };
        try {
            await this.getBwToken();
            if (this.bwtoken && this.bwtoken.split('.').length > 1) {
                const decodedString = Buffer.from(this.bwtoken.split('.')[1], BASE64).toString(BINARY);
                this.userId = JSON.parse(decodedString).sub;
                return this.userId;
            }
            const error = ERROR_CODE.UNAUTHORIZED;
            throw new Error(`${error}`);
        }
        catch (err) {
            const errorInfo = {
                statusCode: err instanceof Error ? Number(err.message) : '',
            };
            return serviceErrorCodeHandler(errorInfo, loggerContext);
        }
    }
    async getBwToken() {
        try {
            const bwTokenResponse = await this.webex.request({
                uri: `${this.webex.internal.services._serviceUrls.broadworksIdpProxy}${BW_TOKEN_FETCH_ENDPOINT}`,
                method: HTTP_METHODS.GET,
            });
            const response = bwTokenResponse.body;
            this.bwtoken = response[TOKEN][BEARER];
        }
        catch (err) {
            log.info(`Broadworks token exception ${err}`, {});
        }
    }
    async setXsiVoiceMessageURI() {
        const loggerContext = {
            file: BROADWORKS_VOICEMAIL_FILE,
            method: 'setXsiVoiceMessageURI',
        };
        let userIdResponse = await this.getUserId();
        this.xsiEndpoint = await getXsiActionEndpoint(this.webex, loggerContext, CALLING_BACKEND.BWRKS);
        this.xsiAccessToken = `${BEARER} ${this.bwtoken}`;
        log.info(`XsiEndpoint is ${this.xsiEndpoint}`, loggerContext);
        if (userIdResponse && typeof userIdResponse !== OBJECT) {
            this.xsiVoiceMessageURI = `${this.xsiEndpoint}/${BW_XSI_ENDPOINT_VERSION}/${USER}/${userIdResponse}/${VOICE_MESSAGING_MESSAGES}`;
            userIdResponse = {
                statusCode: SUCCESS_STATUS_CODE,
                data: {},
                message: SUCCESS_MESSAGE,
            };
        }
        return userIdResponse;
    }
    async getVoicemailList(offset, offsetLimit, sort, refresh) {
        const loggerContext = {
            file: BROADWORKS_VOICEMAIL_FILE,
            method: 'getVoicemailList',
        };
        log.info(`Offset: ${offset} Offset limit: ${offsetLimit} Sort type:${sort}`, loggerContext);
        const urlXsi = `${this.xsiVoiceMessageURI}${JSON_FORMAT}`;
        let messageinfo;
        const sortParam = Object.values(SORT).includes(sort) ? sort : SORT.DEFAULT;
        if (refresh) {
            try {
                const response = await fetch(`${urlXsi}`, {
                    method: HTTP_METHODS.GET,
                    headers: {
                        Authorization: this.xsiAccessToken,
                    },
                });
                if (!response.ok) {
                    throw new Error(`${response.status}`);
                }
                const voicemailListResponse = (await response.json());
                if (Object.keys(voicemailListResponse?.VoiceMessagingMessages?.messageInfoList).length === 0) {
                    messageinfo = [];
                }
                else if (!Array.isArray(voicemailListResponse?.VoiceMessagingMessages?.messageInfoList?.messageInfo)) {
                    messageinfo = Array(voicemailListResponse?.VoiceMessagingMessages?.messageInfoList?.messageInfo);
                }
                else {
                    messageinfo = voicemailListResponse?.VoiceMessagingMessages?.messageInfoList
                        ?.messageInfo;
                    messageinfo = getSortedVoicemailList(messageinfo, sortParam);
                }
                storeVoicemailList(this.context, messageinfo);
            }
            catch (err) {
                const errorInfo = {
                    statusCode: err instanceof Error ? Number(err.message) : '',
                };
                const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
                return errorStatus;
            }
        }
        const { messages, moreVMAvailable } = fetchVoicemailList(this.context, offset, offsetLimit, loggerContext);
        const responseDetails = {
            statusCode: moreVMAvailable ? SUCCESS_STATUS_CODE : NO_VOICEMAIL_STATUS_CODE,
            data: {
                voicemailList: messages,
            },
            message: moreVMAvailable ? SUCCESS_MESSAGE : NO_VOICEMAIL_MSG,
        };
        return responseDetails;
    }
    async getVoicemailContent(messageId) {
        const loggerContext = {
            file: BROADWORKS_VOICEMAIL_FILE,
            method: 'getVoicemailContent',
        };
        try {
            const voicemailContentUrl = `${this.xsiEndpoint}${messageId}`;
            const response = await fetch(`${voicemailContentUrl}`, {
                method: 'GET',
                headers: {
                    Authorization: this.xsiAccessToken,
                },
            });
            if (!response.ok) {
                throw new Error(`${response.status}`);
            }
            const xmlData = await response.text();
            const parser = new DOMParser();
            const xmlDOM = parser.parseFromString(xmlData, XML_TYPE);
            const mediaDetails = xmlDOM.getElementsByTagName(MESSAGE_MEDIA_CONTENT)[0];
            const mediaType = mediaDetails.childNodes[1]?.textContent;
            const mediaContent = mediaDetails.childNodes[2]?.textContent;
            log.info(`Media type is  ${mediaType}`, loggerContext);
            const responseDetails = {
                statusCode: response?.status,
                data: {
                    voicemailContent: {
                        type: mediaType,
                        content: mediaContent,
                    },
                },
                message: SUCCESS_MESSAGE,
            };
            return responseDetails;
        }
        catch (err) {
            const errorInfo = {
                statusCode: err instanceof Error ? Number(err.message) : '',
            };
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            return errorStatus;
        }
    }
    async getVoicemailSummary() {
        return Promise.resolve(null);
    }
    async voicemailMarkAsRead(messageId) {
        const loggerContext = {
            file: BROADWORKS_VOICEMAIL_FILE,
            method: 'voicemailMarkAsRead',
        };
        try {
            const voicemailContentUrl = `${this.xsiEndpoint}${messageId}/${MARK_AS_READ}`;
            const response = await fetch(voicemailContentUrl, {
                method: HTTP_METHODS.PUT,
                headers: {
                    Authorization: this.xsiAccessToken,
                },
            });
            if (!response.ok) {
                throw new Error(`${response.status}`);
            }
            const responseDetails = {
                statusCode: response.status,
                data: {},
                message: SUCCESS_MESSAGE,
            };
            return responseDetails;
        }
        catch (err) {
            const errorInfo = {
                statusCode: err instanceof Error ? Number(err.message) : '',
            };
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            return errorStatus;
        }
    }
    async voicemailMarkAsUnread(messageId) {
        const loggerContext = {
            file: BROADWORKS_VOICEMAIL_FILE,
            method: 'voicemailMarkAsUnread',
        };
        try {
            const voicemailContentUrl = `${this.xsiEndpoint}${messageId}/${MARK_AS_UNREAD}`;
            const response = await fetch(voicemailContentUrl, {
                method: HTTP_METHODS.PUT,
                headers: {
                    Authorization: this.xsiAccessToken,
                },
            });
            if (!response.ok) {
                throw new Error(`${response.status}`);
            }
            const responseDetails = {
                statusCode: response.status,
                data: {},
                message: SUCCESS_MESSAGE,
            };
            return responseDetails;
        }
        catch (err) {
            const errorInfo = {
                statusCode: err instanceof Error ? Number(err.message) : '',
            };
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            return errorStatus;
        }
    }
    async deleteVoicemail(messageId) {
        const loggerContext = {
            file: BROADWORKS_VOICEMAIL_FILE,
            method: 'deleteVoicemail',
        };
        try {
            const voicemailContentUrl = `${this.xsiEndpoint}${messageId}`;
            const response = await fetch(voicemailContentUrl, {
                method: HTTP_METHODS.DELETE,
                headers: {
                    Authorization: this.xsiAccessToken,
                },
            });
            if (!response.ok) {
                throw new Error(`${response.status}`);
            }
            const responseDetails = {
                statusCode: response.status,
                data: {},
                message: SUCCESS_MESSAGE,
            };
            return responseDetails;
        }
        catch (err) {
            const errorInfo = {
                statusCode: err instanceof Error ? Number(err.message) : '',
            };
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            return errorStatus;
        }
    }
    async getVMTranscript(messageId) {
        log.info(`Message Id: ${messageId}`, {});
        return Promise.resolve(null);
    }
    resolveContact(callingPartyInfo) {
        log.info(`Calling Party Info: ${callingPartyInfo}`, {});
        return Promise.resolve(null);
    }
}
